import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [numbers, setNumbers] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [response, setResponse] = useState('');
  const [type, setType] = useState('individual');
  const [qrCode, setQrCode] = useState('');
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [clientLoggedIn, setClientLoggedIn] = useState(false);
  const [scheduleTime, setScheduleTime] = useState('');
  const [templateId, setTemplateId] = useState('');

  useEffect(() => {
    const checkClientStatus = async () => {
      try {
        const { data } = await axios.get('https://1ea3-2401-4900-1f3f-a622-a44d-f84-9e3d-2630.ngrok-free.app/client-status');
        setClientLoggedIn(data.loggedIn);
        setLoading(false);
      } catch (error) {
        console.error('Failed to check client status:', error);
        setLoading(false);
      }
    };

    checkClientStatus();

    const qrEventSource = new EventSource('https://1ea3-2401-4900-1f3f-a622-a44d-f84-9e3d-2630.ngrok-free.app/qr-code');

    qrEventSource.onmessage = (event) => {
      const qrData = event.data.replace(/^data:image\/png;base64,/, '');
      setQrCode(qrData);
    };

    qrEventSource.onerror = () => {
      setLoading(false);
      setQrCode('');
    };

    return () => {
      qrEventSource.close();
    };
  }, []);

  const handleSendMessage = async () => {
    if (sending) return;
    setSending(true);

    const formData = new FormData();
    formData.append('numbers', JSON.stringify(numbers.split('\n')));
    formData.append('message', message);
    formData.append('type', type);
    formData.append('scheduleTime', scheduleTime);
    formData.append('templateId', templateId);

    if (file) {
      formData.append('file', file);
    }

    try {
      const { data } = await axios.post('https://1ea3-2401-4900-1f3f-a622-a44d-f84-9e3d-2630.ngrok-free.app/send-message', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setResponse(data);
    } catch (error) {
      console.error('Error sending message:', error);
      setResponse('Failed to send messages.');
    } finally {
      setSending(false);
    }
  };

  const handleCsvUpload = async (e) => {
    const csvFile = e.target.files[0];
    const formData = new FormData();
    formData.append('file', csvFile);

    try {
      const { data } = await axios.post('https://1ea3-2401-4900-1f3f-a622-a44d-f84-9e3d-2630.ngrok-free.app/upload-csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setNumbers(data.join('\n'));
    } catch (error) {
      console.error('Error uploading CSV:', error);
      setResponse('Failed to upload CSV.');
    }
  };

  const handleDownloadQRCode = () => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${qrCode}`;
    link.download = 'qr-code.png';
    link.click();
  };

  const [sendingPaused, setSendingPaused] = useState(false);

  // Function to handle pause
  const handlePauseSending = () => {
    setSendingPaused(true);
  };

  // Function to handle resume
  const handleResumeSending = () => {
    setSendingPaused(false);
  };

  // Function to handle schedule change
  const handleScheduleChange = () => {
    // Implement logic to change the schedule
  };

  // Function to handle delete messages
  const handleDeleteMessages = async () => {
    try {
      await axios.post('https://1ea3-2401-4900-1f3f-a622-a44d-f84-9e3d-2630.ngrok-free.app/delete-messages');
      setResponse('Messages deleted successfully.');
    } catch (error) {
      console.error('Error deleting messages:', error);
      setResponse('Failed to delete messages.');
    }
  };

  const [schedules, setSchedules] = useState([]); // Array to hold schedules

  const handleAddSchedule = (newSchedule) => {
    setSchedules([...schedules, newSchedule]);
  };

  const handleUpdateSchedule = (index, updatedSchedule) => {
    const newSchedules = [...schedules];
    newSchedules[index] = updatedSchedule;
    setSchedules(newSchedules);
  };

  // Add these to your component


  return (
    <div className="dashboard">
      <aside className="sidebar">
        <h2>Dashboard</h2>
        <ul>
          <li>QR Code Login</li>
          <li>Send Message</li>
        </ul>
      </aside>

      <div className="content">
        {!clientLoggedIn && (
          <div className="qr-section">
            <h2>Scan QR Code</h2>
            {qrCode ? (
              <div>
                <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" />
                <button onClick={handleDownloadQRCode}>Download QR Code</button>
              </div>
            ) : (
              <p>Waiting for QR Code...</p>
            )}
          </div>
        )}

        <div className="message-section">
          <h2>Send Message</h2>
          <textarea
            value={numbers}
            onChange={(e) => setNumbers(e.target.value)}
            placeholder="Enter phone numbers (one per line)"
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message"
          />
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <input
            type="datetime-local"
            value={scheduleTime}
            onChange={(e) => setScheduleTime(e.target.value)}
          />
          <button onClick={handleSendMessage} disabled={sending}>
            {sending ? 'Sending...' : 'Send Message'}
          </button>

          <button onClick={handlePauseSending} disabled={sendingPaused}>
            {sendingPaused ? 'Paused' : 'Pause Sending'}
          </button>
          <button onClick={handleResumeSending} disabled={!sendingPaused}>
            Resume Sending
          </button>

          <h2>Upload CSV</h2>
          <input type="file" accept=".csv" onChange={handleCsvUpload} />

          {response && <p>{response}</p>}
        </div>
      </div>
    </div>
  );
}

export default App;
